<template>
  <div>
    
    <div class="card" >
      <div class="card-body py-3 px-4 d-flex flex-column" style="gap: 1rem;">
        <!-- <div class="card">
          <div class="card-body p-3">
          </div>
        </div> -->
        <div>
          <div v-if="tournament.registration_event1 != null || tournament.registration_event2 != null">
            <div
              class="d-flex align-items-center"
              :class="
								(
									// tournament.match[0].group_member1_score < tournament.match[0].group_member2_score && 
									!tournament.match[0].is_registration_event1_win && 
									tournament.match[0].status == 'finished'
								) && 'loseOpacity'"
              style="gap: 1rem"
              v-if="tournament.registration_event1 != null"
            >
              <div class="flex-fill">
                <p class="m-0 font-weight-bold text-nowrap">
                  {{ tournament.registration_event1.team_name }}
                </p>
                <p class="m-0 text-nowrap">
                  {{ tournament.registration_event1.user.name }}
                </p>
              </div>
              <img
                :src="tournament.registration_event1.url_image"
                style="width: 4rem"
                />
              <div class="scoreSeparator"></div>
              <h4 class="m-0">
                {{ tournament.match[0].group_member1_score }}
              </h4>
              <!-- <div class="d-flex align-items-center">
              </div> -->
            </div>
            <div v-else>
              <h4 class="m-0">{{ tournament.from_tournament1 != null ? '-' : 'BYE' }}</h4>
            </div>
          </div>
          <div v-else>
            <h4 class="m-0">-</h4>
          </div>
        </div>
    
        <div class="text-center py-2 border-top border-bottom d-flex justify-content-between align-items-center">
          <small class="m-0">
            {{ tournament.date_moment.format("DD MMMM YYYY HH:mm") }}
          </small>
          <div class="d-flex" v-if="tournament.registration_event1.team_name != 'BYE' && tournament.registration_event2.team_name != 'BYE'">
            <button class="rounded-circle ml-2 btn-primary" v-show="user != null && user.type != null && user.type.name == 'coordinator'" @click="$emit('onMatchEditClicked', tournament.match[0])" style="height: 3rem; width: 3rem; font-size: .7rem;" >
              Edit
            </button>
            <button class="rounded-circle ml-2 btn-primary" v-show="user != null && user.type != null && user.type.name == 'coordinator' && tournament.registration_event1 != null" @click="$emit('onMatchClicked', tournament.match[0])" style="height: 3rem; width: 3rem; font-size: .7rem;" >
              Detail
            </button>
          </div>
        </div>
    
        <div>
          <div v-if="tournament.registration_event1 != null || tournament.registration_event2 != null">
            <div
              class="d-flex align-items-center"
              :class="
								(
									// tournament.match[0].group_member2_score < tournament.match[0].group_member1_score && 
									!tournament.match[0].is_registration_event2_win && 
									tournament.match[0].status == 'finished'
								) && 'loseOpacity'"
              style="gap: 1rem"
              v-if="tournament.registration_event2 != null"
            >
              <div class="flex-fill">
                <p class="m-0 font-weight-bold text-nowrap">
                  {{ tournament.registration_event2.team_name }}
                </p>
                <p class="m-0 text-nowrap">
                  {{ tournament.registration_event2.user.name }}
                </p>
              </div>
              <img
                :src="tournament.registration_event2.url_image"
                style="width: 4rem"
                />
              <div class="scoreSeparator"></div>
              <h4 class="m-0">
                {{ tournament.match[0].group_member2_score }}
              </h4>
            </div>
            <div v-else>
              <h4 class="m-0">{{ tournament.from_tournament2 != null ? '-' : 'BYE' }}</h4>
            </div>
          </div>
          <div v-else>
            <h4 class="m-0">-</h4>
          </div>
        </div>
        <!-- <div class="card">
          <div class="card-body p-3">
          </div>
        </div> -->
      </div>
    </div>

  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import NoImage from "@/assets/no_image.png";

export default {
  props: ["tournament", "user",],
  components: {},
  data() {
    return {
      base: null,
    };
  },
  async created() {
    this.base = new Base();
  },
  methods: {},
};
</script>

<style lang="scss">
.matchEventGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
.loseOpacity{
  opacity: 0.5;
}
.scoreSeparator{
  background-color: #dee2e6;
  width: 1px;
  height: 4rem;
}
</style>
